import { createEffect, lazy, Show, Suspense } from "solid-js";
import { onMount } from "solid-js";
import { MenuMobileContextProvider } from "~/contexts/MenuMobileContext";
import TopBar from "~/components/shared/TopBar";
import Footer from "~/components/shared/Footer";
import { Toaster } from "solid-toast";

import {
  programBarShouldStack,
  topBarShouldStack,
  useStackingContext,
} from "~/contexts/StackingContext";

import { useWindowSize } from "@solid-primitives/resize-observer";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import SEOCities from "~/components/shared/SEOCities";

import type { MenuItem, Settings } from "~/types/common";
import type { ParentProps } from "solid-js";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";
import { Didomi } from "~/lib/Didomi";
import FormModal from "./FormModal";

import ContactPhoneDialog from "./ContactPhone";
import { useLocation } from "@solidjs/router";
import { Portal } from "solid-js/web";
import { clientOnly } from "@solidjs/start";
import IframeFormListener from "./IframeFormListener";

const HeadBand = clientOnly(() => import("./HeadBand"));

const MediaOverlay = lazy(() => import("./MediaOverlay"));

type PageContentProps = {
  menuMain: MenuItem[];
  menuFooter: MenuItem[];
  settings: Settings;
};

export default function PageContent(props: ParentProps<PageContentProps>) {
  const [, { setTopBarHeaderStack, setProgramHeaderStack }] =
    useStackingContext();
  const [mediaProvider] = useMediaOverlayContext();

  const loc = useLocation();

  if (
    setTopBarHeaderStack === undefined ||
    setProgramHeaderStack === undefined
  ) {
    throw new Error("useStackingContext must be used within its provider");
  }

  const handleScroll = () => {
    setTopBarHeaderStack(topBarShouldStack());
    setProgramHeaderStack(programBarShouldStack());
  };

  const attachExternalLinksBehavior = function () {
    for (let c = document.getElementsByTagName("a"), a = 0; a < c.length; a++) {
      const b = c[a];
      const isExt =
        b.getAttribute("href") &&
        (b.protocol === "https:" || b.protocol === "http") &&
        b.hostname !== location.hostname;

      const isAltarea = b.textContent!.toLowerCase().includes("altarea");

      if (isExt || isAltarea) {
        b.target = "_blank";
        b.classList.add("ext");
        b.setAttribute("rel", "noopener noreferrer nofollow");
      }
    }
  };

  onMount(() => (onscroll = handleScroll));
  onMount(() => attachExternalLinksBehavior());

  const size = useWindowSize();

  const [
    ,
    {
      setViewPortIsLessThan768,
      setViewPortIsMoreThan768,
      setViewPortIsLessThan1024,
      setViewPortIsLessThan1210,
      setViewPortIsLessThan1280,
    },
  ] = useViewportSizeContext();

  createEffect(() => {
    setViewPortIsLessThan768!(size.width < 768);
    setViewPortIsMoreThan768!(size.width > 768);
    setViewPortIsLessThan1024!(size.width < 1024);
    setViewPortIsLessThan1210!(size.width < 1210);
    setViewPortIsLessThan1280!(size.width < 1280);
  });

  const isSearchPage = () =>
    loc.pathname.includes("/programme-immobilier-neuf");

  const isParkingPage = () => loc.pathname.includes("/parking");

  const isProgram = () => loc.pathname.includes("/program");

  const isFrontPage = () => loc.pathname === "/";

  /*

  const isLanding = () => loc.pathname.includes("/landing");

  const isJourney = () => loc.pathname.includes("/journey");

  const isPlotPage = () => loc.pathname.includes("/vendre-mon-terrain");

  const isEngaPage = () =>
    loc.pathname.includes(
      "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements",
    );

  const isPressReleasesPage = () => loc.pathname.includes("/espace-presse");

  const isPressRelease = () => loc.pathname.includes("/presse_release");

  const isFaq = () => loc.pathname.includes("/faq");

  const isCustomLandingAccess = () =>
    loc.pathname.includes(
      "/cogedim-pour-vous/pourquoi-choisir-cogedim/le-nouveau-neuf/cogedim-access",
    );

  const isSimulatorPTZ = () =>
    loc.pathname.includes(
      "/habiter/les-outils-pour-preparer-votre-achat/calcul-ptz.html",
    );
  */

  return (
    <>
      <Didomi />
      <IframeFormListener settings={props.settings} />
      <MenuMobileContextProvider>
        <TopBar
          menuMainItems={props.menuMain}
          settings={props.settings}
          forceStacked={
            true
            /*isParkingPage() ||
            isSearchPage() ||
            isLanding() ||
            isJourney() ||
            isPlotPage() ||
            isEngaPage() ||
            isPressReleasesPage() ||
            isPressRelease() ||
            isFaq() ||
            isCustomLandingAccess() ||
            isSimulatorPTZ()
            */
          }
        />
      </MenuMobileContextProvider>

      <Show
        when={
          (props.settings.headband.disp_contents &&
            !isProgram() &&
            !isFrontPage()) ||
          (props.settings.headband.disp_home && isFrontPage()) ||
          (props.settings.headband.disp_programs && isProgram())
        }
      >
        <HeadBand />
      </Show>

      <Show when={mediaProvider.type}>
        <Suspense>
          <MediaOverlay
            type={mediaProvider.type!}
            content={mediaProvider.content!}
          />
        </Suspense>
      </Show>
      <Suspense>{props.children}</Suspense>

      <Show
        when={isParkingPage() || isSearchPage()}
        fallback={
          <>
            <SEOCities />
            <Footer menuItems={props.menuFooter} settings={props.settings} />
          </>
        }
      >
        <Portal mount={document.getElementById("footer-alternate")!}>
          <SEOCities />
          <Footer menuItems={props.menuFooter} settings={props.settings} />
        </Portal>
      </Show>

      <FormModal />
      <ContactPhoneDialog />
      <Toaster position="bottom-center" gutter={8} />

      <div id="lot-drawer-receiver" />
      <div id="media-overlay-receiver" />
      <div id="dialog-receiver" />
      <div id="form-modal-receiver" />
    </>
  );
}
