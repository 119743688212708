import type { RouteDefinition, RouteSectionProps } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getParkings } from "~/lib/fetcher";
import { Show } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";
import { MetaTags } from "~/components/shared/MetaTags";
import ParkingsPage from "~/components/Parkings/ParkingsPage";
import { Title } from "@solidjs/meta";

export const route = {
  load({ params: { department, city } }) {
    void getParkings(department, city);
  },
} satisfies RouteDefinition;

export default function ParkingsRoute(props: RouteSectionProps) {
  const data = createAsync(
    () =>
      getParkings(
        props.params.region,
        props.params.department,
        props.params.city,
      ),
    {
      name: "parkings",
      deferStream: true,
    },
  );

  const [, { setTemplate }] = useEventsContext();
  setTemplate("parkings");

  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Investissez dans un parking avec Cogedim. Découvrez nos offres d'achat de parking en France et trouvez le parking qui vous convient.",
    },
  };

  return (
    <>
      <Show when={data()}>
        <Title>Investir dans un parking : achat parking avec Cogedim</Title>
        <MetaTags tags={[metaDesc]} />
        <ParkingsPage
          programs={data()!.programs}
          regions={data()!.regions}
          departments={data()!.departments}
          cities={data()!.cities}
          landings={data()!.landings}
        />
      </Show>
    </>
  );
}
