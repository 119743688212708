import { onMount } from "solid-js";
import { useFormContext } from "~/contexts/FormContext";
import {
  type OpenModalFormOptions,
  useModalFormContext,
} from "~/contexts/ModalFormContext";
import { getWrapper } from "~/lib/fetcher";
import type { Settings } from "~/types/common";
import type { FormSystemName } from "~/types/form_system_name";

export default function IframeFormListener(props: { settings: Settings }) {
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [, { setValue }] = useFormContext();
  onMount(() => {
    window.addEventListener(
      "message",
      function (e) {
        const form = getFormSystemName(e.data.form);

        if (!form) {
          console.error("Unknown form", e.data.form);
          return;
        }

        const found = props.settings.domains_allowed_to_open_forms.find(
          function (str) {
            if (!str) {
              console.error("Empty domain");
              return false;
            }

            if (str.indexOf("*.") !== -1) {
              const search = str.slice(str.indexOf("*.") + 2);
              return e.origin.indexOf(search) !== -1;
            }
            return str === e.origin;
          },
        );

        if (!found) {
          console.error("Unknown domain", e.origin);
          return;
        }

        if (e.data.action === "openModalForm") {
          if (e.data.program_nid) {
            const nid = parseInt(e.data.program_nid);

            if (nid > 0) {
              getWrapper(nid).then((wrapper) => {
                if (
                  "program" in wrapper &&
                  "code" in wrapper.program &&
                  wrapper.program.code === 404
                ) {
                  console.error("Program not found", nid);
                } else {
                  const lotId = e.data.lot_id
                    ? Number(e.data.lot_id) > 0
                      ? Number(e.data.lot_id)
                      : undefined
                    : undefined;

                  // Find the matching lot if we have a valid ID
                  const lot = lotId
                    ? wrapper.lots.find(
                        (lot) => lot.drupal_internal__id === lotId,
                      )
                    : undefined;

                  openModalFormProxy(
                    openModalForm,
                    setModalFormHeadline,
                    setValue,
                    form,
                    { wrapper, lot },
                    e.data.subject,
                  );
                }
              });
              return;
            }
          } else {
            openModalFormProxy(
              openModalForm,
              setModalFormHeadline,
              setValue,
              form,
              {},
              e.data.subject,
            );
          }
        }
      },
      false,
    );
  });

  return <></>;
}

function openModalFormProxy(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openModalFormFn: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setModalFormHeadlineFn: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValueFn: any,
  form: FormSystemName,
  options: OpenModalFormOptions,
  subjectValue?: "1" | "2" | "3",
) {
  openModalFormFn!(form, options);
  setModalFormHeadlineFn!(getFormHeadline(form));

  if (form === "advisor" && subjectValue) {
    const subject = parseInt(subjectValue);

    switch (subject) {
      case 1:
        setValueFn!("subject", "question");
        break;
      case 2:
        setValueFn!("subject", "rdv");
        break;
      case 3:
        setValueFn!("subject", "appel");
        break;
      default:
        console.error("Unknown subject", subjectValue);
        break;
    }
  }
}

function getFormSystemName(name: string): FormSystemName | undefined {
  if (name === "contact_us") {
    return "contact-us";
  }

  if (
    [
      "callback",
      "advisor",
      "leaflet",
      "blueprint",
      "qualification",
      "financial-study",
    ].includes(name)
  ) {
    return name as FormSystemName;
  }

  return undefined;
}

function getFormHeadline(form: FormSystemName): string {
  switch (form) {
    case "contact-us":
      return "Contact";
    case "callback":
      return "Être appelé";
    case "advisor":
      return "Contactez un conseiller";
    case "leaflet":
      return "Télécharger la brochure";
    case "blueprint":
      return "Télécharger le plan";
    case "qualification":
      return "Préciser votre besoin";
    case "financial-study":
      return "Étude personnalisée";
    default:
      return "";
  }
}
