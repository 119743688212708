import type { RouteDefinition } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getHomeDwell } from "~/lib/fetcher";
import { Show, Suspense } from "solid-js";
import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import { useEventsContext } from "~/contexts/EventsContext";
import GtmVars from "~/components/shared/Trackers/GtmVars";
import FrontPageDwell from "~/components/Home/FrontPageDwell";

export const route = {
  load() {
    void getHomeDwell();
  },
} satisfies RouteDefinition;

export default function HomeDwellRoute() {
  const data = createAsync(() => getHomeDwell(), {
    name: "home-dwell",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("home-dwell");

  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Optimisez votre achat immobilier neuf avec des conseils pour concrétiser votre projet et bénéficier des nombreux avantages du neuf.",
    },
  };

  const gtmValues = {
    "all.pageType": "homepage-dwell",
    "all.mainCategory": "home-dwell",
    "all.category": "Non défini",
    "all.subCategory": "Non défini",
    "all.subsubCategory": "Non défini",
    "all.subsubsubCategory": "Non défini",
    template: "home-dwell",
  };

  return (
    <>
      <Suspense>
        <Show when={data()}>
          <GtmVars values={gtmValues} />
          <Title>Achat immobilier neuf : réussir votre projet immobilier</Title>
          <MetaTags tags={[metaDesc]} />
          <FrontPageDwell data={data()!} />
        </Show>
      </Suspense>
    </>
  );
}
