import * as Sentry from "@sentry/solidstart";
import { solidRouterBrowserTracingIntegration } from "@sentry/solidstart/solidrouter";
import { mount, StartClient } from "@solidjs/start/client";
// import "solid-devtools/setup";
// import { attachDevtoolsOverlay } from "@solid-devtools/overlay";

// attachDevtoolsOverlay();

if (import.meta.env.PROD && import.meta.env.VITE_COG_ENV === "prod") {
  Sentry.init({
    environment: import.meta.env.VITE_COG_ENV,
    dsn: "https://b0a50e03ff113c81fc014a1dea732a8a@o89517.ingest.us.sentry.io/4507549885399040",
    integrations: [
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ["cog-solid"],
        // Defines how to handle errors that contain third party stack frames.
        // Possible values are:
        // - 'drop-error-if-contains-third-party-frames'
        // - 'drop-error-if-exclusively-contains-third-party-frames'
        // - 'apply-tag-if-contains-third-party-frames'
        // - 'apply-tag-if-exclusively-contains-third-party-frames'
        behaviour: "apply-tag-if-exclusively-contains-third-party-frames",
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
      solidRouterBrowserTracingIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

/*window.addEventListener("vite:preloadError", (event) => {
  Sentry.captureException(event);
  window.location.reload(); // for example, refresh the page
});*/

mount(() => <StartClient />, document.getElementById("app")!);
